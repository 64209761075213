import { render, staticRenderFns } from "./WaitlistDisplayPublic.vue?vue&type=template&id=58b8e6b7&scoped=true&"
import script from "./WaitlistDisplayPublic.vue?vue&type=script&lang=js&"
export * from "./WaitlistDisplayPublic.vue?vue&type=script&lang=js&"
import style0 from "./WaitlistDisplayPublic.vue?vue&type=style&index=0&id=58b8e6b7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b8e6b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VDataTable,VProgressLinear})
