<template>
  <message-card v-if="!division.showTeams || division.hideWaitlist">
    <h2>Teams are not available yet.</h2>
  </message-card>

  <v-data-table
    v-else
    :items="teams"
    :headers="headers"
    :loading="division.loading"
    :options.sync="page"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:loading>
      <v-progress-linear color="color3" indeterminate></v-progress-linear>
    </template>
    <template v-slot:[`item.unix`]="{item}">
      {{item.unix | unixToLocalDt}}
    </template>
    <template v-slot:[`item.players`]="{ item }">
      <div>
        <div class="noWrap" v-for="p in item.players" :key="p.id">
          {{p.name}}{{division.showCommit && p.commitAbbr ? ` (${p.commitAbbr})` : ''}}
          <i class="fas fa-heart ml-2" v-if="p.isBookmarked(user)"></i>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: ['division', 'search', 'waitlist', 'columns', 'showPlayers'],
  data () {
    return {
      page: { itemsPerPage: 10, sortBy: ['unix'], sortDesc: [false] }
    }
  },
  computed: {
    teams () {
      return this.division.waitlistTeams
    },
    headers () {
      const heads = [
        { text: 'Name', value: 'name', align: 'left', sortable: true }
      ]
      if (this.showPlayers) {
        heads.push({ text: 'Players', value: 'players', align: 'left', sortable: false })
      }
      this.columns && heads.push(...this.columns.map(m => {
        return {
          text: m.header,
          value: m.value,
          align: 'left',
          sortable: true
        }
      }))
      if (this.division.waitlistSort !== 'name') {
        heads.push(
          { text: 'Date Registered', value: 'unix', align: 'left', sortable: true }
        )
      }
      return heads
    }
  },
  methods: {
    sort () {
      this.page.sortBy = [this.division.waitlistSort]
      this.page.sortDesc = [!['unix', 'name'].includes(this.division.waitlistSort)]
    }
  },
  watch: {
    'division.waitlistSort': 'sort'
  },
  mounted () {
    this.sort()
  }
}
</script>

<style scoped>
  .noWrap {
    white-space: nowrap;
  }
  .deleted {
    text-decoration: line-through
  }
</style>
